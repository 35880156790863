import axios from "axios";
import Vue from "vue";

// FIXME use global var timeout
axios.defaults.timeout = 20000; // needed for external service pythonanywhere if they took more time
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URI

Vue.prototype.$http = axios;

// if token available
const token = localStorage.getItem("user-token");
if (token) {
  //TODO improve check with other solution in auth, user api and setup interceptor
  axios.defaults.headers.common["Authorization"] = token;
}
export default axios;

