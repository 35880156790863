//import Swal from "sweetalert2";
//import Worker from "@/js/web/test.worker.js";
//import Worker from "../js/workers/test.worker";
//import Worker from "worker-loader!../js/workers/TestWor.js";
//const Worker = require('Worker!@/js/web/test.worker.js');
//import Worker from 'worker-loader!../js/worker/test.worker.js';
//import Worker from 'worker-loader!./Worker.js';


export const updateMixin = {
  data: function() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    if(navigator?.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      });
    } else {
      Logger.warn("ServiceWorker not supported by this browser.")
    }
  },
  mounted: function() {
    // Logger.trace("globalMixin", global)

  },
  computed: {

  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event && event.detail;
      this.updateExists = true;

      this.$notify({
        title: "Software update",
        message: "The software has been updated. The page will be refreshed.",
        type: "warning",
        timeout: 0,
        horizontalAlign: "right",
        verticalAlign: "top",
        icon: 'nc-icon nc-app'
      });
      this.refreshApp();
      /*
      Swal.fire({
        title: 'Software update',
        icon: 'info',
        text: "The software has been updated. The page will be refreshed.",
        confirmButtonText: "Ok"
      }).then((result) => {
        this.refreshApp();
      });

       */
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },

    registerEventSource() {
      let evtSource = new EventSource('/api/sse/');
      Logger.debug(evtSource.withCredentials);
      Logger.debug(evtSource.readyState);
      Logger.debug(evtSource.url);

      evtSource.onopen = function() {
        Logger.debug("Connection to server opened.");
      };

      evtSource.onmessage = function(e) {
        Logger.debug( "message: " + e.data);
      };

      evtSource.addEventListener("ping", function(e) {
        Logger.debug( "event ping + message: " + e.data);
      }, false);

      evtSource.onerror = function() {
        Logger.debug("EventSource failed.");
      };
    }
  }
};
